<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4 create-new'>
      <b-col cols='12'>
        <i class='fa fa-backward'></i>
        <router-link :to="{ name: PremiumPath.name }">
          Premium Offers
        </router-link>
      </b-col>
      <b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
          <div class='card'>
            <div class='bg-success p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Create New Premium Offer</h5>
            </div>
            <div class='card-body'>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Offers Name'>
                    <b-form-input id='price' v-model='form.name' type='text' placeholder='Offers Name' required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Duration' label-for='duration'>
                    <b-form-select id='duration' v-model='form.duration'
                      :options='durationOptions' required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Status'>
                    <b-form-select id="offer_status" v-model="form.offer_status" :options="offerStatusOptions" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Web Price USD'>
                    <b-form-input id='price' v-model='form.price_usd' type='number' step='any'
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Web Price IDR'>
                    <b-form-input id='price' v-model='form.price_idr' type='number' step='any'
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='6'>
                  <b-form-group label='IOS Tier'>
                    <multiselect v-model='form.ios_tier' label='tier_code' track-by='tier_code' placeholder='Ios Tier'
                      open-direction='bottom' :options='iosTierSearchOptions' :searchable='true'
                      :loading='isIosTierSearch' :close-on-select='true' :multiple='false' @search-change='iosTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.ios_tier ? form.ios_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.ios_tier ? form.ios_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>

                <b-col lg='6'>
                  <b-form-group label='Android Tier'>
                    <multiselect v-model='form.android_tier' label='tier_code' track-by='tier_code'
                      placeholder='Ios Tier' open-direction='bottom' :options='androidTierSearchOptions'
                      :searchable='true' :loading='isAndroidTierSearch' :close-on-select='true' :multiple='false'
                      @search-change='androidTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.android_tier ? form.android_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.android_tier ? form.android_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model='form.is_free' :value=true unchecked-value='false'> Free Offer
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant='primary' type='submit'>create</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Premium } from '../../router/marketing';
import constant from '../../store/constant';

const durationPremium = Object.entries(constant.PREMIUM_OFFER_DURATION)
  .map(val => ({ text: val[0], value: val[1] }));

export default {
  name: 'newPremiumOffer',
  data() {
    return {
      debounce: null,
      form: {
        name: null,
        price_usd: 0,
        price_idr: 0,
        ios_tier: null,
        android_tier: null,
        duration: null,
        is_free: false,
        offer_status: null,
      },
      durationOptions: [
        { text: 'Select Type', value: null },
        ...durationPremium,
      ],
      offerStatusOptions: [
        { value: null, text: 'Select Status'},
        { value: constant.OFFER_STATUS.READY_FOR_SALE, text: 'On Sale' },
        { value: constant.OFFER_STATUS.NOT_FOR_SALE, text: 'Not Sale' },
      ],
      isIosTierSearch: false,
      iosTierSearchOptions: [],
      isAndroidTierSearch: false,
      androidTierSearchOptions: [],
      PremiumPath: Premium,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.offerspremium.isError,
      isLoading: (state) => state.offerspremium.isLoading,
      errorMessage: (state) => state.offerspremium.errorMessage,
      successMessage: (state) => state.offerspremium.successMessage,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);

      // reset form
      this.onReset();
    },
  },
  created() {
  },
  methods: {
    ...mapActions('offerspremium', [
      'postPremiumOffers'
    ]),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTier',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: 'searchIosTier',
    }),

    onSubmit(event) {
      event.preventDefault();
      const data = this.form;
      if (!data.android_tier) {
        this.messageAlert('error', 'Android Tier not selected');
        return
      }
      if (!data.ios_tier) {
        this.messageAlert('error', 'IOS Tier not selected');
        return
      }
      
      const payload = {
        name: data.name,
        offer_status: data.offer_status,
        is_free: false,
        is_active: true,
        price_usd: data.price_usd,
        price_idr: data.price_idr,
        android_tier_id: data.android_tier.id,
        ios_tier_id: data.ios_tier.id,
        duration: data.duration,
      };
      this.postPremiumOffers(payload);
    },

    onReset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data.rows;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = response.data.data.rows;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
  filters: {
    toCurrencyID: function (val) {
      return parseInt(val).toLocaleString('id');
    }
  }
};
</script>
